import { Button, TextField, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Container from "@mui/material/Container";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    margin: "10px 0",
  },
  btn: {
    margin: "15px 0",
  },
}));

export default function Forgot() {
  const classes = useStyles();
  const { forgotPassword } = useAuth();

  const [state, setState] = useState({
    error: null,
    loading: false,
    data: {},
    message: null,
  });

  const handleChange = (e) => {
    setState({
      ...state,
      data: {
        ...state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setState({
      ...state,
      error: null,
      loading: true,
    });
    try {
      await forgotPassword(state.data.email);
      setState({
        ...state,
        loading: false,
        message: "Please check your email for reset instructions.",
      });
    } catch (err) {
      console.log(err.message);
      setState({
        ...state,
        error: err.message,
        loading: false,
      });
    }
  };

  return (
    <div>
      <Container maxWidth="xs">
        <div className={classes.paper}>
          <Typography className={classes.title}>Forgot Password</Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Email"
              type="email"
              name="email"
              margin="normal"
              onChange={handleChange}
              variant="outlined"
              fullWidth
              required
            />
            {state.message && <Typography>{state.message}</Typography>}
            <Button
              color="primary"
              variant="contained"
              fullWidth
              className={classes.btn}
              size="large"
              type="submit"
              disabled={state.loading}
            >
              Submit
            </Button>
          </form>
          <Link to="/">Back to Login</Link>
        </div>
        {state.error && (
          <Typography color="secondary">{state.error}</Typography>
        )}
      </Container>
    </div>
  );
}
